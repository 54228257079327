import React, { Component, ComponentType } from 'react'

import './Index.less'
import '@babel/polyfill'

import Throbber from '../common/components/Throbber'
import { createRoot } from 'react-dom/client'

interface IIndexState {
  App: ComponentType | null
}

class Index extends Component<{}, IIndexState> {
  state = {
    App: null
  }

  async componentDidMount() {
    import('./components/app/controls/App').then(App => {
      this.setState({ App: App.default })
    })
  }

  render() {
    const { App } = this.state
    const isAppLoaded = Boolean(App)
    return <Throbber show={!isAppLoaded}>{isAppLoaded && React.createElement(App!)}</Throbber>
  }
}

renderApp()

function renderApp() {
  const container = document.getElementById('main')
  if (!container) {
    return
  }
  const root = createRoot(container)
  root.render(<Index />)
}
